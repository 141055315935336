<template>
    <main>
        <section>
            <footer class="flex">
                <div class="block">
                    <img :src="require(`@/assets/${block}`)" alt="" />
                </div>
                <!-- <nav>
                  <ul class="flex"> 
                      <li>
                          <a href="">home</a>
                      </li>
                      <li>
                          <a href="">company</a>
                      </li>
                      <li>
                          <a href="">careers</a>
                      </li>
                  </ul>
              </nav> -->
                <div class="logo">
                    <img :src="require(`@/assets/${logo}`)" alt="" />
                </div>

                <div class="block">
                    <img :src="require(`@/assets/${block}`)" alt="" />
                </div>
            </footer>
        </section>
    </main>
</template>

<script>
import svg from "../exports/media";
export default {
    data() {
        return {
            block: svg.svgAssets.block,
            logo: svg.smallLogos.logo,
        };
    },
};
</script>

<style lang="scss" scoped>
main {
    padding: 20px 0px;
    section {
        footer {
            nav {
                width: 20%;
                li {
                    text-transform: uppercase;
                    font-size: 0.9em;
                }
            }
        }
    }
}
@media (max-width: 1300px) {
    main {
        section {
            footer {
                nav {
                    width: 30%;
                }
            }
        }
    }
}
@media (max-width: 856px) {
    main {
        section {
            footer {
                .block {
                    align-self: flex-end;
                }
                nav {
                    ul {
                        flex-direction: column;
                        align-items: flex-start;
                        li {
                            margin: 20px 0px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 500px) {
    main {
        section {
            footer {
                align-items: flex-end;
                .block {
                    display: none;
                }
                .logo {
                    order: 1;
                }
            }
        }
    }
}
</style>
