<template>
    <div class="wrap_element">
        <div :class="{ wrap: 1 == 1, 'two-tier': data.id % 2 == 0 }">
            <div class="img">
                <img v-if="data.id % 2 == 0" :src="require(`@/assets/${asset.rs}`)" alt="" />
                <img v-else :src="require(`@/assets/${asset.ds}`)" alt="" />
            </div>
            <div :class="{ text: 1 == 1, flex_end: data.id % 2 == 0 }">
                <p data-animation="paragraph">{{ data.info }}</p>
            </div>
        </div>
        <br />
        <div class="date">
            <small data-animation="paragraph">{{ data.year }}</small>
            <h4 data-animation="paragraph">{{ data.quarter }}</h4>
        </div>
    </div>
</template>

<script>
import svg from ".././exports/media";
export default {
    data() {
        return { asset: svg.timeline };
    },
    props: ["data"],
};
</script>

<style lang="scss" scoped>
.flex_end {
    align-self: flex-end;
    margin-top: 10px;
}
.two-tier {
    position: relative;
    top: 150px;
}
.wrap_element {
    width: 100%;
    @media screen and (max-width: 390px) {
        width: 50%;
    }
    .wrap {
        display: flex;
        width: 450px;
        @media (max-width: 480px) {
            width: 350px;
        }
        .text {
            width: 60%;
            white-space: normal;
            p {
                color: #151439;
                line-height: 29px;
                font-style: normal;
                font-weight: normal;
            }
        }
    }
    .date {
        margin-top: 34%;
        padding: 0px 10px;
        border-left: 3px solid #151439;
        small {
            color: #5b5b74;
            font-size: 1em;
            margin-bottom: 5px;
        }
        h4 {
            font-size: 2.1em;
            width: fit-content;
            color: #151439;
        }
    }
}
</style>
