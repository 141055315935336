const smallLogos = {
    cardinal: "cardinal_svg_nfanoe.svg",
    rivet: "rivet_svg_p2983k.svg",
    plugeth: "Plugeth_svg_s6flfr.svg",
    logo: "logo_o9fhoi.svg",
};

const svgAssets = {
    arrow: "arrow_mcpf9g.svg",
    block: "block_gbk9sd.svg",
    paint: "paint_lew5dk.svg",
    menu: "menu_gh7e0e.svg",
};

const bigLogos = {
    cardinal: "cardinal_vlvj2s.svg",
    plugeth: "plugeth_bvumad.svg",
};

const geometric = {
    firstGeomet: "geomet_1_ojsnqx.svg",
    major: "major_bu7fj1.svg",
    minor: "minor_n6c3yd.svg",
    mirror: "mirror_frxbiv.svg",
    line_mobile: "line_mobile_fo50e3.svg",
};

const timeline = {
    ds: "Timeline_atgavz.svg",
    rs: "Timeline___yg5p1s.svg",
    line: "line_zwjwnh.svg",
};

export default {
    smallLogos,
    timeline,
    svgAssets,
    bigLogos,
    geometric,
};
