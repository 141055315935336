<template>
    <main id="Timeline">
        <div class="section">
            <div class="begin">
                <h2 data-animation="heading">Timeline</h2>
                <p data-animation="paragraph">
                    The timeline is a physically illustrated medium of how far we have come over the last few years. on mobile, scroll from left to
                    right
                </p>
            </div>
            <div class="container time_line">
                <div v-for="item in data" :key="item.id" class="about">
                    <Timeline_data :data="item" />
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { gsap, Circ } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import db from "../data/data.json";
import Timeline_data from "./timeline_data.vue";
export default {
    name: "Timeline",
    components: { Timeline_data },
    data() {
        return {
            data: db.Timeline,
        };
    },
    mounted() {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.matchMedia({
            "(min-width:600px)": () => {
                let tl = new gsap.timeline();
                let sections = gsap.utils.toArray(".wrap_element");

                tl.to(sections, {
                    xPercent: -90 * (sections.length - 1),
                    ease: Circ.easeOut,
                    scrollTrigger: {
                        trigger: "#Timeline p",
                        snap: 1 / (sections.length - 1),
                        end: "+=3000",
                        scrub: -1,
                        pin: ".containment",
                    },
                });
            },
        });
    },
};
</script>

<style lang="scss" scoped>
main {
    padding: 60px 0px;
    .section {
        background-image: url(~@/assets/line_zwjwnh.svg);
        background-repeat: no-repeat;
        background-position: left 63%;
        background-size: 100%;
        .begin {
            width: 75%;
            margin: 0 auto;
            @media (max-width: 600px) {
                width: 90%;
            }
        }
        .container {
            overflow-x: visible;
            overflow-y: hidden;
            white-space: nowrap;
            scroll-behavior: smooth;
            padding-left: 12%;
            @media (max-width: 600px) {
                padding-left: 5%;
            }
            margin: 6rem 0px;
            .about {
                display: inline-block;
            }
        }
    }
}
</style>
