<template>
    <main>
        <section id="values">
            <div class="begin">
                <h2 data-animation="heading">Values</h2>
                <p data-animation="paragraph">
                    Our values are those core principles that we live our lives by. Values form the foundation of who we are as a company.
                </p>
            </div>
            <div class="wrap">
                <div class="greed grid">
                    <div class="grid_item">
                        <h3 data-animation="heading">Community</h3>
                        <p data-animation="paragraph">
                            We believe in the power of community, understanding that open and collaborative systems are the most powerful ones. We
                            unify the blockchain ecosystem and strengthen the decentralized web through every line of collaborative code. Our
                            contributors will always be rewarded for the value of their contributions.
                        </p>
                    </div>
                    <div class="grid_item">
                        <h3 data-animation="heading">Dependability</h3>
                        <p data-animation="paragraph">
                            Like the humble rivet, you may not even know it's there, but you depend on it every day. That's why we strive to exemplify
                            and honor the rivet in everything we create and everything we do, because world changing systems cannot be permitted to
                            fall apart unexpectedly
                        </p>
                    </div>
                    <div class="grid_item">
                        <h3 data-animation="heading">Diversity</h3>
                        <p data-animation="paragraph">
                            Diversity makes our team and our community stronger. Through diverse teams, we dissolve the boundaries of our own
                            perspectives and collective imaginations
                        </p>
                    </div>
                    <div class="grid_item">
                        <h3 data-animation="heading">privacy</h3>
                        <p data-animation="paragraph">
                            Privacy is built into our infrastructure and our DNA. Our policy honors the roots of decentralization and ensures that our
                            users’ data is never shared or compromised
                        </p>
                    </div>
                    <div class="grid_item">
                        <h3 data-animation="heading">transparency</h3>
                        <p data-animation="paragraph">
                            We are committed to remaining open-source; to secure trust, to hold ourselves accountable, and to help others learn
                            through our own experimentation.
                        </p>
                    </div>
                    <div class="grid_item">
                        <h3 data-animation="heading">creativity</h3>
                        <p data-animation="paragraph">We strive to both inspire and pioneer innovation that is undefined and limitless</p>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script></script>

<style lang="scss" scoped>
main {
    background-image: url("~@/assets/paint_lew5dk.svg");
    background-repeat: no-repeat;
    background-size: 10%;
    background-position: right bottom;
    padding: 80px 0px;
    margin-top: 30px;
    section {
        .wrap {
            width: 80%;
        }
        .greed {
            margin-top: 100px;
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 40px;
            grid-column-gap: 30px;

            .grid_item {
                p {
                    letter-spacing: -0.5px;
                    word-spacing: 1px;
                    line-height: 27px;
                    width: 90%;
                }
            }
        }
        .letter {
            margin-top: 80px;
            p {
                text-align: center;
                font-style: italic;
                font-family: 0.9em;
                color: #2901c2;
            }
        }
    }
}
@media (max-width: 765px) {
    main {
        background-image: url("~@/assets/line_mobile_fo50e3.svg");
        background-size: 5%;
        padding: 60px 0px;
        section {
            .wrap {
                width: 100%;
                margin: 0 auto;
            }
            .greed {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}
</style>
